* {
  margin: 0;
  padding: 0;
  /* font-family: 'Courier New', Courier, monospace; */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Standard elements */
a {
  text-decoration: none;
}

button {
  padding: 5px;
  background-color: #6c6c6c;
  color: white;
  font-weight: bold;
}

form {
  margin: 10px;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

form input {
  margin: 10px;
  padding: 10px;
}

form button {
  margin: 10px;
  padding: 10px;
}

ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

li {
  list-style: none;
  margin: 0 10px;
  font-size: 20px;
}

table {
  table-layout: fixed;
  width: 100%;
}

th {
  background-color: #6c6c6c;
  color: white;
  padding: 5px 10px;
}

td {
  border: 1px solid black;
  padding: 5px 10px;
}



/* Classes */
.container {
  background-color: #f2f2f2;
  padding: 25px 50px;
}

.footer {
  background-color: #f2f2f2;
  padding: 25px;
  text-align: right;
  font-size: .75rem;
}

.homeContainer {
  width: 75%;
  margin: 0 auto;
}

.icon {
  width: 32px;
}

.it {
  color: #f36e21;
}

.deleteIcon {
  width: 16px;
}

.logo-img {
  width: 20%;
}

.row {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.justify-right {
  text-align: right;
}

.justify-center {
  text-align: center;
}

.summary {
  text-align: right;
  padding: 10px;
}

.reportContainer {
  text-align: right;
}




/* Mobile devices */
@media only screen and (max-width: 600px) {

  table, th, td, .tableContainer {
    width: 100%;
    table-layout: fixed;
    overflow: auto;
  }

  .logo-img {
    width: 100%;
  }

  .icon {
    width: 24px;
  }
}